import React from 'react';
import { Container } from 'react-bootstrap';

const NotFoundPage = () => {
  return (
    <Container style={{ marginTop: '250px' }}>
      <h1 className="notfound">Something went wrong</h1>
      <h2>
        <a href="/" rel="noopener noreferrer">
          Go Home
        </a>
      </h2>
    </Container>
  );
};

export default NotFoundPage;
